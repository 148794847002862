import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './NewPassword.css';

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Extract the email from the location state passed from ForgotPassword.js
    if (location.state?.email) {
      setEmail(location.state.email);
    } else {
      // Redirect back to ForgotPassword or show an error
      setError('Email address is not provided. Please request a reset code again.');
    }
  }, [location.state]);

  const handleNewPasswordSubmission = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    }
    // Password complexity requirements
    if (
      newPassword.length < 8 ||
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/\d/.test(newPassword) ||
      !/[!@#$%^&*()_+[\]{}|\\;:'",.<>?~`]/.test(newPassword)
    ) {
      setError('Password does not meet complexity requirements.');
      return;
    }

    setLoading(true);
    setError(''); // Reset error message
    setMessage(''); // Reset success message

    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await fetch(`${API_URL}/api/users/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code, newPassword }),
      });

      // Check for non-200 status code
      if (!response.ok) {
        // Attempt to read the response text
        const errorText = await response.text();
        throw new Error('Failed to reset password. ' + errorText);
      }

      setMessage('Password has been successfully reset.  Please stand by...');
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after a successful reset
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  // Render error or success messages and the form
  return (
    <div className="new-password-container">
      <h2>Reset Your Password</h2>
      <p>Please check your email for a reset token</p>
      
      {message && <p className="message success">{message}</p>}
      {error && <p className="message error">{error}</p>}
      <form onSubmit={handleNewPasswordSubmission}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email address"
          readOnly // The email field is read-only because it's passed from the ForgotPassword component
          required
        />
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Reset Code"
          required
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          required
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm New Password"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Resetting...' : 'Reset Password'}
        </button>
      </form>
    </div>
  );
};

export default NewPassword;
