

import { BrowserRouter as Router, Route,  Routes, Navigate} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Background from './Background';
import Header from './Header';
import Footer from './Footer';
import SignUp from './SignUp';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import VerifyEmail from './VerifyEmail';
import NewPassword from './NewPassword'; // This would be where the user sets their new password
import './styles.css';
import Chat from './Chat';
import './Chat.css';
import HomeContent from './HomeContent'
import ProfileUpdate from './ProfileUpdate'; 
import AboutPage from './AboutPage';
import CertsPage from './Certs';
import WeatherWidget from './WeatherWidget'
import { profileImages } from './ProfileUpdate'; // import profileImages here
import Contact from './Contact';



const App = () => {
  const CHAT_ID = process.env.REACT_APP_CHAT_ID;
  const [isExpanded, setIsExpanded] = useState(false);
  const [setShowSignUp] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [chats, setChats] = useState([]);
  const [showNewChatForm, setShowNewChatForm] = useState(false);
  const [newChatTitle, setNewChatTitle] = useState('');
  const [viewState, setViewState] = useState('chatList'); // 'chatList', 'chat' or 'info'
  const [selectedChatName, setSelectedChatName] = useState('');
  
  const [chatMessages, setChatMessages] = useState([]);
  const [isWeatherWidgetExpanded, setIsWeatherWidgetExpanded] = useState(false);
  const [setShowChatForm, setSetShowChatForm] = useState(false);
  const [trips, setTrips] = useState([]);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleWeatherWidget = () => {
    setIsWeatherWidgetExpanded(prevState => !prevState);
};

  const authenticateUser = async (user, token) => {
    // Clear old chats
    setChats([]);

    // Then fetch new chats
    setIsAuthenticated(true);
    setUser(user);
    
    // Fetch the chats that the user is a part of
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, {
      headers: {
        'Content-Type': 'application/json',

      },
      credentials: 'include',
    });
    
    const data = await response.json();
    setChats(data);
    
  };

  const logoutUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/logout`, {
        method: 'POST',
        credentials: 'include', // Include credentials to clear the cookie
      });
    
      if (response.ok) {
        setIsAuthenticated(false);
        setUser(null);
        setChats([]);
        // ... any other state cleanup you need
      } else {
        console.error('Logout failed:', response.status);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const selectChat = async (chatId, chatName) => {
  setChatId(chatId);
  setSelectedChatName(chatName);

  // Fetch the messages for the selected chat
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/messages`, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (response.ok) {
    const messages = await response.json();
    // set the state with the new messages
    setChatMessages(messages); 
  } else {
    // handle errors
    console.error('Error fetching chat messages:', response.status);
  }
};

  const handleOpenSignUp = () => {
    setShowSignUp(true);
  };

  const handleCloseSignUp = () => {
    setShowSignUp(false);
  };
  const handleSuccessfulSignup = () => {
    alert('Congratulations! Your account has been created. Please log in.');
    setSignupSuccess(true);
    setShowLoginForm(false);
    setShowSignUp(false);
  };
  const handleCreateNewChat = async () => {
    if (!newChatTitle || !user || !user._id) {
      alert('Chat name or user details are missing.');
      return;
    }
  
    // Send a POST request to create a new chat
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        credentials: 'include', 
        body: JSON.stringify({
          name: newChatTitle,
          users: [user._id]
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Add an alert to notify the user of successful chat creation
      alert("Chat successfully created!");
  
      // Reset newChatTitle
      setNewChatTitle("");
  
      // Update the chat list (assuming you have a setChats function)
      setChats(prevChats => [...prevChats, data]);
  
    } catch (error) {
      console.error("Error creating new chat:", error);
    }
  };
  
  // Function to delete a chat
const deleteChat = async (chatId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        
      },
      credentials: 'include',
    });

    if (response.ok) {
      // Update the state to reflect the chat deletion
      setChats(currentChats => currentChats.filter(chat => chat._id !== chatId));
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error deleting chat:', error);
  }
};


useEffect(() => {
  const checkAuthentication = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/check-auth`, {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
        setUser(data.user);

        if (data.isAuthenticated) {
          // Fetch chat data after confirming the user is authenticated
          const chatsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
          });
          if (chatsResponse.ok) {
            const chatsData = await chatsResponse.json();
            setChats(chatsData); // Update the chats state
          }
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
        setChats([]); // Clear chats state
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setIsAuthenticated(false);
      setUser(null);
      setChats([]); // Clear chats state
    }
  };

  checkAuthentication();
}, []);



return (
  <Router>
    <div>
      <Header
        isAuthenticated={isAuthenticated}
        user={user}
        handleOpenSignUp={handleOpenSignUp}
        logoutUser={logoutUser}
        authenticateUser={authenticateUser}
        showLoginModal={() => setShowLoginForm(true)}
      />
      <Background />

      <Routes>
        <Route path="/home" element={<HomeContent 
          isAuthenticated={isAuthenticated}
          setShowChatForm={setShowChatForm}
          user={user}
          handleOpenSignUp={handleOpenSignUp}
          logoutUser={logoutUser}
          authenticateUser={authenticateUser}
          
          trips={trips}
          


          // ... other necessary props 
        />} />
        <Route path="/" element={<Navigate to="/home" />} /> {/* This redirects from root to /home */}
        <Route path="/signup" element={
          <SignUp 
            handleCloseSignUp={handleCloseSignUp} 
            handleSuccessfulSignup={handleSuccessfulSignup}
          />} />

        <Route path="/verify-email" element={<VerifyEmail />} />

        <Route path="/login" element={
          <Login 
            handleCloseLogin={() => setShowLoginForm(false)} 
            handleOpenSignUp={handleOpenSignUp}
            authenticateUser={authenticateUser}
            // ... any other necessary props
          />}/>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/new-password" element={<NewPassword />} />

        <Route path="/chat" element={
          <Chat 
          onClose
          setViewState={setViewState} 
          userId={user?._id}
          chats={chats} 
          selectChat={selectChat} 
          chatId={chatId} 
          
          showNewChatForm={showNewChatForm}
          handleCreateNewChat={handleCreateNewChat}
          newChatTitle={newChatTitle}
          setNewChatTitle={setNewChatTitle}
          setShowNewChatForm={setShowNewChatForm}
          chatName={selectedChatName} 
          profileImages={profileImages}
          deleteChat={deleteChat}
       />} />  
        

        <Route path="/WeatherWidget" element={<WeatherWidget  />} />
        
        <Route path="/profile-update" element={<ProfileUpdate />} /> 

        <Route path="/contact" element={<Contact />} />

        <Route path="/about" element={<AboutPage />} /> 

        <Route path="/Certifications" element={<CertsPage />} />

      </Routes>
      

      <Footer />

      {/* ... any other components that should appear on all pages can go here ... */}
    </div>
  </Router>
);
};

export default App;