import React from 'react';
import './styles.css';

const Background = () => {
  return (
    <div className="Background-container">
      <div className="Background-content">
        {/* Remove the <h1> tag for the home-heading */}
      </div>
    </div>
  );
};

export default Background;
