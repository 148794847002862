import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';
import './ProfileUpdate.css';

import profile1 from './profilePics/profile1.png';
import profile2 from './profilePics/profile2.png';
import profile3 from './profilePics/profile3.png';
import profile4 from './profilePics/profile4.png';
import profile5 from './profilePics/profile5.png';
import profile6 from './profilePics/profile6.png';
import profile7 from './profilePics/profile7.png';
import profile8 from './profilePics/profile8.png';
import profile9 from './profilePics/profile9.png';
import profile10 from './profilePics/profile10.png';
import profile11 from './profilePics/profile11.png';
import profile12 from './profilePics/profile12.png';
import profile13 from './profilePics/profile13.png';
import profile14 from './profilePics/profile14.png';
import profile15 from './profilePics/profile15.png';
import profile16 from './profilePics/profile16.png';
import profile17 from './profilePics/profile17.png';
import profile18 from './profilePics/profile18.png';
import profile19 from './profilePics/profile19.png';
import profile20 from './profilePics/profile20.png';
import profile21 from './profilePics/profile21.png';
import profile22 from './profilePics/profile22.png';
import profile23 from './profilePics/profile23.png';
import profile24 from './profilePics/profile24.png';
import profile25 from './profilePics/profile25.png';
import profile26 from './profilePics/profile26.png';
import profile27 from './profilePics/profile27.png';
import profile28 from './profilePics/profile28.png';
import profile29 from './profilePics/profile29.png';
import profile30 from './profilePics/profile30.png';


export const profileImages = [
    profile1,
    profile2,
    profile3,
    profile4,
    profile5,
    profile6,
    profile7,
    profile8,
    profile9,
    profile10,
    profile11,
    profile12,
    profile13,
    profile14,
    profile15,
    profile16,
    profile17,
    profile18,
    profile19,
    profile20,
    profile21,
    profile22,
    profile23,
    profile24,
    profile25,
    profile26,
    profile27,
    profile28,
    profile29,
    profile30
];

const ProfileUpdate = ({ token }) => {
    const [isEditing, setIsEditing] = useState(false); // NEW: State for edit mode
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [currentProfilePic, setCurrentProfilePic] = useState(null);  // default value set to null, adjust if necessary
    const [bio, setBio] = useState('');

    useEffect(() => {
        // Fetch the user's current profile data when the component is mounted
        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/updateProfile`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        
                    }
                    
                });
                const data = await response.json();
                setCurrentProfilePic(profileImages[data.profileImage ?? 0]);
                setBio(data.bio);
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };

        fetchProfileData();
    }, []);

    const [errorMessage, setErrorMessage] = useState('');

    const navigateToNextImage = () => {
        const nextIndex = (currentIndex + 1) % profileImages.length;
        setCurrentIndex(nextIndex);
        
    };
    const navigateToPrevImage = () => {
        const prevIndex = (currentIndex - 1 + profileImages.length) % profileImages.length;
        setCurrentIndex(prevIndex);
        
    };
    const handleImageSelect = () => {
        setSelectedImageIndex(currentIndex);
        
        alert(`Image ${currentIndex + 1} selected.`);
    };

    const handleClose = () => {
        // Logic to close the modal (if any)
        
        // Route back to home
        navigate('/');
    }


    const handleSaveProfile = async () => {
        if (selectedImageIndex === null) {
            setErrorMessage("Please click image to select a profile image.");
            return;
        }
    
        if (!bio.trim()) {
            setErrorMessage("Bio cannot be empty.");
            return;
        }
    
        const profileData = {
            profileImage: selectedImageIndex,
            bio: bio
        };
    
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/updateProfile`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(profileData)
            });
    
            const data = await response.json();
    
    
            if (response.ok) {
                navigate('/');
            } else {
                setErrorMessage(data.error || "An error occurred.");
            }
        } catch (error) {
            console.error("Error during profile update:", error);  // Debug log
            setErrorMessage("There was an error updating the profile.");
        }
    };
    


    return (
        <div className="profile-modal">
            <div className="profile-update-container">
                <button className="closeButton" onClick={handleClose}>x</button>
                <h2>Profile</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                {!isEditing ? (
                    <>
                        <img src={currentProfilePic} alt="Current Profile" />
                        <label>Bio</label> {/* "Bio" label added here */}
                        <p>{bio}</p>
                        <button onClick={() => setIsEditing(true)}>Edit</button>
                    </>
                ) : (
                    <>
                        <div className="carousel-container">
                            <button className="navigate-prev" onClick={navigateToPrevImage}>Prev</button>
                            <img 
                                src={profileImages[currentIndex]} 
                                alt={`Profile ${currentIndex + 1}`}
                                className={`carousel-image ${selectedImageIndex === currentIndex ? 'selected' : ''}`} 
                                onClick={handleImageSelect} 
                            />
                            <button className="navigate-next" onClick={navigateToNextImage}>Next</button>
                        </div>
                        <div className="bio">
                            <textarea
                                placeholder="Add your bio..."
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                            />
                        </div>
                        <button className="SaveButtonProfile" onClick={handleSaveProfile}>Save</button>
                        
                    
                    </>
                )}
            </div>
        </div>
    );
};

export default ProfileUpdate;