import React, { useState } from 'react';
import axios from 'axios';
import './WeatherWidget.css';
import { useNavigate } from 'react-router-dom';

function WeatherWidget() {
    const [weatherData, setWeatherData] = useState({ current: null, forecast: [] });
    const [location, setLocation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;
    const [showMore, setShowMore] = useState(false);
    const navigate = useNavigate(); // Create the navigate function
    const handleClose = () => {
        navigate('/home'); // Navigate to the home route
    };
    const handleShowMoreToggle = () => {
    setShowMore(!showMore);
    };


    const fetchWeather = async () => {
        if (!location) return;
    
        // Reset error message each time we fetch new data
        setErrorMessage('');
    
        try {
            // Fetching current weather data
            const currentResponse = await axios.get(`https://api.weatherbit.io/v2.0/current?city=${location}&key=${API_KEY}`);
            
            // Fetching 7-day forecast data
            const forecastResponse = await axios.get(`https://api.weatherbit.io/v2.0/forecast/daily?city=${location}&key=${API_KEY}`);
            
            // Updating state with both current weather and forecast data
            setWeatherData({
                current: currentResponse.data.data[0],
                forecast: forecastResponse.data.data
            });
        } catch (error) {
            if (error.response && error.response.status === 429) {
                // Handle rate limit error
                setErrorMessage('WeatherBit API limit reached. Please try again later.');
            } else {
                // Handle other errors
                setErrorMessage('An error occurred while fetching weather data.');
            }
            console.error('Error fetching weather:', error);
        }
    };

    const convertTemperature = (tempCelsius) => {
        return (tempCelsius * 9/5 + 32).toFixed(2);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchWeather();
    };

        // Convert and format sunrise and sunset times
    const formatSunTime = (timeString) => {
        // Assuming the time is given in 'HH:MM' format
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setUTCHours(hours);
        date.setUTCMinutes(minutes);
        // Format the time in a locale-appropriate way
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <div className="weather-container">
            <button onClick={handleClose} className="close-button-weather">X</button>
            <div className="title-weather">
                Portfolio Weather App
            </div>

            
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    value={location} 
                    onChange={e => setLocation(e.target.value)} 
                    placeholder="Enter location (state, city)"
                />
                <button type="submit">Get Weather</button>
            </form>

                        {/* Display current weather data */}
            {weatherData.current && (
            <div className="current-weather">
                
                <h2>Current Weather</h2>
                <img
                src={`${process.env.PUBLIC_URL}/weatherbitIcons/icons/${weatherData.current.weather.icon}.png`}
                alt={weatherData.current.weather.description}
                />
                <p>Temperature: {convertTemperature(weatherData.current.temp)} °F</p>
                <p>Humidity: {weatherData.current.rh}%</p>
                <p>Wind: {weatherData.current.wind_cdir_full} at {(weatherData.current.wind_spd * 2.237).toFixed(2)} mph</p>
                
                {/* Button to show/hide more details */}
                <button onClick={handleShowMoreToggle}>{showMore ? "Less" : "More"}</button>

                {/* Additional details, shown when showMore is true */}
                {showMore && (
                <>
                    <p>Pressure: {(weatherData.current.pres * 0.0295301).toFixed(2)} inHg</p> {/* Pressure in inches of mercury */}
                    <p>UV Index: {weatherData.current.uv}</p>
                    <p>Air Quality Index (AQI): {weatherData.current.aqi}</p>
                    <p>Visibility: {weatherData.current.vis} miles</p>
                    <p>Cloud Coverage: {weatherData.current.clouds}%</p>
                    <p>Sunrise: {formatSunTime(weatherData.current.sunrise)}</p>
                    <p>Sunset: {formatSunTime(weatherData.current.sunset)}</p>
                </>
                )}
            </div>
            )}

            {/* Display 7-day forecast data */}
            <div className="weather-data-container">
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {weatherData.forecast.length > 0 && (
                    <div className="forecast-container">
                        {weatherData.forecast.map((day, index) => {
                            const date = new Date(day.valid_date);
                            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                            const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                            const dayOfWeek = daysOfWeek[date.getDay()];
                            const month = monthsOfYear[date.getMonth()];
                            const dateOfMonth = date.getDate();
                            
                            return (
                                <div className="forecast-item" key={index}>
                                    <h2>{`${dayOfWeek}, ${month} ${dateOfMonth}`}</h2>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/weatherbitIcons/icons/${day.weather.icon}.png`}
                                        alt={day.weather.description}
                                    />
                                    <p>Temperature: {convertTemperature(day.temp)} °F</p>
                                    <p>Humidity: {day.rh}%</p>
                                    <p>Wind Speed: {(day.wind_spd * 2.237).toFixed(2)} mph</p>
                                    <p>Chance of Rain: {day.pop}%</p>
                                    <p>Precipitation: {day.precip} mm</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default WeatherWidget;
