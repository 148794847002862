import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Certs.css'; // Ensure you have proper styling in this CSS file
import hubspotcertImage from './Pictures/hubspotCert.png'; // Make sure the path is correct

function Certs() {
  const navigate = useNavigate();

  // Function to handle closing of the About page which navigates to home
  const handleClose = () => {
    navigate('/home'); // Navigate to the home route
  };

  return (
    <div className="cert-container">
      <button onClick={handleClose} className="close-button">X</button>
      <h1 className="cert-title">Certifications</h1>
      <div className="cert-description">
        <h2>HubSpot Certification</h2>
        {/* You can add more details about your certification here */}
      </div>
      
      <img src={hubspotcertImage} alt="HubSpot Certification" className="cert-image" />
      {/* Add a description or title if needed */}
      
    </div>
  );
}

export default Certs;
