import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSignUp = async () => {
    // Validation
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setMessage('Please fill in all fields.');
      return;
    }
    if (password !== confirmPassword) {
      setMessage("Passwords don't match.");
      return;
    }
    // Password complexity requirements
    if (password.length < 8) {
    setMessage('Password must be at least 8 characters long.');
    return;
    }
    if (!/[A-Z]/.test(password)) {
    setMessage('Password must contain at least one uppercase letter.');
    return;
    }
    if (!/[a-z]/.test(password)) {
    setMessage('Password must contain at least one lowercase letter.');
    return;
    }
    if (!/\d/.test(password)) {
    setMessage('Password must contain at least one numeric digit.');
    return;
    }
    if (!/[!@#$%^&*()_+[\]{}|\\;:'",.<>?~`]/.test(password)) {
    setMessage('Password must contain at least one special character.');
    return;
    }

    const API_URL = process.env.REACT_APP_API_URL;

    setIsLoading(true); // Begin loading
    setMessage(''); // Clear any previous messages

    try {
      const response = await fetch(`${API_URL}/api/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
        }),
      });

      const data = await response.json();
      setIsLoading(false); // End loading

      if (response.ok) {
        setMessage('Registration successful! Please check your email for the verification code.');
        // Navigate to verification page
        navigate('/verify-email', { state: { email } });
      } else if (response.status === 400 && data.message.includes('already registered')) {
        setMessage('Email already registered but not verified. Please check your inbox or click here to resend the email.');
      } else {
        setMessage(data.message || 'An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      setIsLoading(false);
      setMessage('Network error: Could not connect to the server.');
    }
  };

  const handleClose = () => {
  navigate('/login');  
};

  return (
    <div className="signup-modal">
      <div className="signup-form-container">
        <button className="close-button" onClick={handleClose}>
          X
        </button>
        <h2 className="signup-title">Sign Up</h2>
        {message && <p className="message">{message}</p>}
        <input
          type="text"
          value={firstName}
          onChange={handleFirstNameChange}
          placeholder="First Name"
          className="form-field"
        />
        <input
          type="text"
          value={lastName}
          onChange={handleLastNameChange}
          placeholder="Last Name"
          className="form-field"
        />
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          className="form-field"
        />
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          className="form-field"
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirm Password"
          className="form-field"
        />
        <button onClick={handleSignUp} className="form-button" disabled={isLoading}>
          {isLoading ? 'Signing Up...' : 'Sign Up'}
        </button>
      </div>
    </div>
  );
};

export default SignUp;
