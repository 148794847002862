import React from 'react';
import './Chat.css';

const ChatList = ({ chats, selectChat, deleteChat, profileImages }) => {
  // Function to handle the chat deletion confirmation
  const handleDeleteClick = (event, chatId) => {
    event.stopPropagation(); // Prevent the chat from being selected when clicking the delete button
    const isConfirmed = window.confirm("Are you sure you want to delete this chat and all associated messages?");
    if (isConfirmed) {
      deleteChat(chatId);
    } 
  };

  return (
    <div className="chat-list-container">
      {chats.map((chat) => (
        <div key={chat._id} className="chat">
          {/* Chat Info */}
          <div className="chat-info" onClick={() => selectChat(chat._id, chat.name)}>
            {/* Display the profile image based on the profilePicIndex associated with each chat */}
            {typeof chat.profilePicIndex === 'number' && 
              <img 
                src={profileImages[chat.profilePicIndex]} 
                alt={`${chat.name} Profile`} 
                className="chat-profile-pic" 
              />
            }
            <span className="chat-title">{chat.name}</span>
          </div>
          {/* Delete Button */}
          <button 
            onClick={(event) => handleDeleteClick(event, chat._id)} 
            className="delete-chat-button">
              Delete
          </button>
        </div>
      ))}
    </div>
  );
};

export default ChatList;