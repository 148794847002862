import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './VerifyEmail.css';
const VerifyEmail = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleVerify = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const email = location.state?.email; // Retrieve email from state passed via navigate

    if (!verificationCode) {
      setMessage('Please enter the verification code.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/users/verify-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, verificationCode }),
      });

      if (response.ok) {
        setMessage('Email verified successfully. You can now log in.');
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
      } else {
        setMessage('Invalid or expired verification code. Please try again or request a new code.');
      }
    } catch (error) {
      setMessage('Error verifying email. Please try again.');
    }
  };

  return (
    <div className="verify-email-container">
      <h2>Email Validation</h2>
      <p>Please Check your Email for a token</p>
      {message && <p className="message">{message}</p>}
      <input
        type="text"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        placeholder="Verification Code"
      />
      <button onClick={handleVerify}>Verify</button>
    </div>
  );
};

export default VerifyEmail;
