import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-text">© 2024 digitalRenaissance.app
. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
