import React from 'react';
import './Contact.css'; // Make sure to have this CSS file with styles in the same directory
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Contact = () => {
  const navigate = useNavigate(); // Create the navigate function

  // Function to handle closing of the contact page which navigates to home
  const handleClose = () => {
    navigate('/home'); // Navigate to the home route
  };
  // This function will load the HubSpot script and initialize the form
  const loadHubSpotForm = () => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44679076",
          formId: "28e15e98-5de6-4f71-af86-0d06999fa81c",
          target: '#hubspotForm'
        });
      }
    };
    document.body.appendChild(script);
  };

  React.useEffect(() => {
    loadHubSpotForm();
  }, []);

  return (
    <div className="contact-container">
      <button onClick={handleClose} className="close-button">X</button> {/* Close button to navigate home */}
      <h1 className="contact-heading">Contact Us</h1>
      <p className="contact-subheading">Please fill out the form below to get in touch with us.</p>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default Contact;
