import React, { useState, useEffect } from 'react';
import Login from './Login';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';



const Header = ({ isAuthenticated, user, handleOpenSignUp, logoutUser, authenticateUser }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/login") {
      setShowLoginForm(true);
    } else {
      setShowLoginForm(false);
    }
  }, [location.pathname]);

  const handleLogin = () => {
    navigate("/login");
    setShowLoginForm(true);
  };

  const handleLogout = () => {
    // Here we remove the token and update the isAuthenticated state
    logoutUser(); // Use the logoutUser function passed as prop
  };

  const closeLoginForm = () => {
    setShowLoginForm(false);
  };

  return (
    <header className="header-container">
      
      <div className="header-title">digitalRenaissance.app</div>
      {isAuthenticated ? ( // If authenticated, show user's first name and logout button
        <>
          {user && <div className="user-greeting">Welcome, {user.firstName} {user.lastName}!</div>} {/* Display user's first name */}
          <div className="logout-button" onClick={handleLogout}>Logout</div> {/* Added logout button */}
        </>
      ) : (
        <div className="login-button" onClick={handleLogin}>Login</div> // If not authenticated, show login button
      )}
      {showLoginForm && <Login handleCloseLogin={closeLoginForm} handleOpenSignUp={handleOpenSignUp} authenticateUser={authenticateUser} />}
    </header>
  );
};

export default Header;
