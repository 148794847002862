import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutPage.css';

function AboutPage() {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/home');
  };

  return (
    <div className="about-container">
      <button onClick={handleClose} className="close-button">X</button>
      <div className="about-title">
        About Me
      </div>
      <div className="about-content">
        <h2>Developer: Nick Goodman</h2>
        <p>
          With a strong foundation in technology, my focus has been on creating intuitive interfaces and ensuring efficient server management. My commitment to technology and continuous improvement is reflected in the practical and user-centric solutions.
        </p>
        <p>
          LinkedIn Profile: <a href="https://www.linkedin.com/in/nicholas-goodman-093973152/" target="_blank" rel="noopener noreferrer">nicholas-goodman</a>
        </p>
        <p>
          Academic Qualifications include an Associate's Degree in Business Management from Utah Valley University. I'm further enhancing my technical knowledge by pursuing a degree in Cyber Security at Western Governors University.
        </p>
        <h3>Professional Experience:</h3>
        <ul>
          <li>
            <strong>Freelance Software Developer:</strong> An ongoing journey since June 2023, where I focus on building full-stack applications, ensuring dynamic, responsive, and intuitive user experiences.
          </li>
          <li>
            <strong>Technical Support at Global Payments Integrated:</strong> From November 2021 to June 2023, where I resolved complex payment processing and networking issues.
          </li>
          <li>
            <strong>Data Integrity Specialist at Solcius LLC:</strong> Between March 2020 and October 2021, I analyzed and improved solar production estimates, enhancing departmental functions.
          </li>
          <li>
            <strong>Proposal Generator at Solcius LLC:</strong> Where I leveraged SketchUp to design solar solutions for potential customers.
          </li>
          <li>
            <strong>United States Marine Corps Reserve:</strong> Providing leadership as an infantry team leader and martial arts instructor, serving honorably from 2011 to 2018.
          </li>
        </ul>
        <h3>Current Projects:</h3>
        <p>
          I'm actively running side projects that involves freelance development, helping clients bring their full-stack web applications to life. Moreover, I'm proud to showcase my portfolio here on "https://digitalrenaissance.app", which not only highlights my technical skills but also shows my commitment to security and best practices in software development.
        </p>
        <p>
          Feel free to reach out to discuss my work or if you're interested in collaborating on future projects.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;