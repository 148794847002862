import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (event) => {
    event.preventDefault(); // Prevent the form from causing a page refresh
    setLoading(true);
  
    try {
      // Update the URL to include the full path to your server
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgot-password`, { email });
      if (response.status === 200) {
        navigate('/new-password', { state: { email } });
      } else {
        setError('Failed to send password reset email. Please try again later.');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <form onSubmit={handleResetPassword}>
        <input
          className="forgot-password-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required // Ensure the email field cannot be empty
        />
        <button 
          className="forgot-password-button" 
          type="submit"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Reset Password'}
        </button>
      </form>
      {error && <p className="message error">{error}</p>}
    </div>
  );
};

export default ForgotPassword;