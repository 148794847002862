

import React, { useState } from 'react';
import weatherIconButton from './Pictures/weather10.png';

import profileIcon from './Pictures/profile26.png'
import experienceIcon from './Pictures/experienceIcon.png'
import aboutIcon from './Pictures/aboutIcon.png'
import { useNavigate } from 'react-router-dom';
import { AiOutlineMessage } from 'react-icons/ai';
import ContactIcon from './Pictures/Contact2.png';
import hubspotIcon from './Pictures/mountainIcon101.png';
// Importing the Font Awesome library and the required icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import ProfileUpdate from './ProfileUpdate'; // Make sure to import ProfileUpdate
import './HomeContent.css';

const HomeContent = ({ isAuthenticated, trips }) => {
    
    const [activeSet, setActiveSet] = useState('set1'); // Ensure this state is defined within the component
    const [showChatForm, setShowChatForm] = useState(false);
    const [isSubMessageVisible, setIsSubMessageVisible] = useState(false);

    const toggleSubMessage = () => {
        
        const newVisibility = !isSubMessageVisible;
        setIsSubMessageVisible(prevState => !prevState);
        
    };
    
    const navigate = useNavigate();
    const handleProfileCreationClick = () => {
    if (!isAuthenticated) {
        alert('Please login/create an account to go to profile.');
        return;
        }
    
        navigate('/profile-update');
    };
    const metroidvaniaClick = () => {
        // navigate('/Metroidvania');
        //alert('The Metroidvania section is currently a work in progress and not available at the moment. Please check back later!');
        navigate('/contact');
    };

    

    const handleChatButtonClick = () => {
        if (!isAuthenticated) {
          alert('Please login/create an account to send messages.');
          return;
        }
        navigate('/chat');
        setShowChatForm(true); 
      };
    
      const handleWeatherButtonClick = () => {
        // Removed the isAuthenticated check
        navigate('/WeatherWidget');
    };


    // State to track which set of buttons to display
    const [buttonSet, setButtonSet] = useState('set1');

    // Function to change the set of buttons
    const handleSetChange = () => {
        setButtonSet(currentSet => currentSet === 'set1' ? 'set2' : 'set1');
    };
    const toggleActiveSet = () => { // This function should be defined within the component
        setActiveSet(current => current === 'set1' ? 'set2' : 'set1');
    };
        

    const HubspotClick = () => {
        alert("This feature is not yet available.");
        // Comment out or remove the existing code if you don't want to perform these actions
        // const url = 'https://44679076.hs-sites.com/final101';
        // window.open(url, '_blank');
        // setShowChatForm(true); 
      };

      const experienceClick = () => {
        navigate('/Certifications');
        
      };

      const aboutClick = () => {
        navigate('/about');
      };
    return (
        <div className="home-container">
            <p className="disclaimer">
                Important Notice: The website is currently in testing. We appreciate your understanding and welcome any feedback during this phase of development.
            </p>
            <div className="welcome-message">
                Welcome to my Portfolio 
            </div>
            <p className="welcome-sub">Nick Goodman's Full Stack Portfolio</p>

            <button type="button" className="info-btn" onClick={toggleSubMessage}>
                {isSubMessageVisible ? "click here for less" : "click here for more"}
            </button>
            {isSubMessageVisible && (
                <>
                    <div className="sub-message">
                        Built from scratch using React on the frontend, Node.js on the backend, and MongoDB for data storage. Explore the app have fun. Stay tuned for more features.   
                    </div>

                    <div className="chat-button" onClick={handleChatButtonClick} title="Messenger">
                        <AiOutlineMessage />
                    </div>

                    <div className="button-strip-container">
                        {/* Conditional rendering based on activeSet state */}
                        {activeSet === 'set1' && (
                            <>
                                <div onClick={metroidvaniaClick} className="Metroidvania-button" title="Contact Us">
                                    <img src={ContactIcon} alt="Metroidvania Icon" className="Metroidvania-icon" />
                                </div>

                                <div onClick={experienceClick} className="experience-button" title="Certifications">
                                    <img src={experienceIcon} alt="experience Icon" className="experience-icon" />
                                </div>
                                
                                <div onClick={aboutClick} className="about-button" title="About">
                                    <img src={aboutIcon} alt="about Icon" className="about-icon" />
                                </div>
                            </>
                        )}
                        {/* Add a similar conditional block for activeSet === 'set2' with the new set of buttons */}
                        {activeSet === 'set2' && (
                            <>
                                <div onClick={HubspotClick} className="Hubspot-button" title="Hubspot Projects">
                                    <img src={hubspotIcon} alt="hubspot Icon" className="Hubspot-icon" />
                                </div>

                                <div onClick={handleProfileCreationClick} className="profile-button" title="Create a Profile">
                                    <img className="profile-icon" src={profileIcon} alt="Profile Icon" />
                                </div>

                                <div onClick={handleWeatherButtonClick} className="weather-widget-Button" title="Weather Widget">
                                    <img src={weatherIconButton} alt="Weather widget Icon" className="weather-widget-icon" />
                                </div>
                                
                            </>
                        )}

                            {/* Button to toggle between the button sets */}
                        <button onClick={toggleActiveSet} className="toggle-button">
                            {activeSet === 'set1' ? <FontAwesomeIcon icon={faArrowRight} /> : <FontAwesomeIcon icon={faArrowLeft} />}
                        </button>
                    </div>
                    
                    
                    

                </>
            )}
        </div>
    );
};

export default HomeContent;