import React, { useState } from 'react';
import PasswordReset from './ForgotPassword'; // Import the PasswordReset component
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ handleOpenSignUp, handleCloseLogin, authenticateUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  

  const handleLogin = async () => {
    const API_URL = process.env.REACT_APP_API_URL;

    try {
        const response = await fetch(`${API_URL}/api/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
            }),
            credentials: 'include',
        });

        if (response.ok) {
            const { firstName, lastName, email, _id } = await response.json();
            if (typeof authenticateUser === 'function') {
                authenticateUser({ firstName, lastName, email, _id });
            } else {
                console.error('authenticateUser prop received is not a function');
            }
            navigate('/');
            handleCloseLogin();
        } else if (response.status === 429) {
            // Handle rate limiting case
            alert('Too many login attempts. Please try again in 15 minutes.');
        } else {
            const error = await response.json();
            if (response.status === 401) {
                alert('Invalid username or password');
            } else {
                console.error('Login failed:', response.status, 'Error:', error.message);
            }
        }
    } catch (error) {
        console.error('Error occurred during login:', error);
    }
};

  const handleClose = () => {
    navigate('/'); 
    handleCloseLogin();
  };


  const handlePasswordReset = () => {
    // navigate to the forgot password page, assuming the route is '/forgot-password'
    navigate('/forgot-password'); 
  };

  const handleResetFormClose = () => {
    setShowPasswordReset(false);
  };

  const handleSignUp = () => {
    navigate('/signup');  // Redirect to the Signup page
  };


  return (
    <div className="login-modal">
      {showPasswordReset ? (
        <PasswordReset handleResetFormClose={handleResetFormClose} />
      ) : (
        <div className="login-form-container">
          <button className="Login-close-button" onClick={handleClose}>
            X
          </button>

          <h2 className="login-title">Login</h2>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            className="form-field"
          />
          <div className="password-container"> {/* Add this div */}
          <input
            type={showPassword ? "text" : "password"} // Toggle between text and password
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
            className="form-field"
          />
          <img 
            src={showPassword ? '/eye-slash.svg' : '/eye.svg'}
            onClick={togglePasswordVisibility}
            alt="Toggle Password Visibility"
            className="password-icon"
            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
          />
        </div>

          <button onClick={handleLogin} className="form-button">
            Login
          </button>
          <button onClick={handlePasswordReset} className="form-button">
            Forgot Password?
          </button>
          <div className="form-link">
            Don't have an account?
            <button onClick={handleSignUp} className="form-button">
              Sign Up
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
