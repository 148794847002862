import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatList from './ChatList';
import './Chat.css';

const Chat = (
  { 
  profileImages, 
  onClose, 
  setViewState,
  userId, 
  chats, 
  selectChat, 
  chatId, 
  showNewChatForm, 
  handleCreateNewChat,
  newChatTitle,
  setNewChatTitle,
  setShowNewChatForm,
  chatName,
  deleteChat
}) => {

 
  
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const messagesEndRef = useRef(null);  // Initialize the ref here
  const [isChatListModalExpanded, setChatListModalExpanded] = useState(false);
  const navigate = useNavigate();
  
  const toggleChatListModal = () => {
    setChatListModalExpanded(!isChatListModalExpanded);
  };
  
  const handleSendMessage = () => {
    if (!newMessage) {
      alert('Message field is empty.');
      return;
    }
    
    if (!userId || !chatId) return;

    fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        
      },
      credentials: 'include', // Add this line to include credentials/cookies in the request
      body: JSON.stringify({
        userId: userId,
        message: newMessage,
      }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      
      setMessages((prevMessages) => [...prevMessages, data]);
      setNewMessage('');
    })
    .catch((error) => console.error('Error sending message:', error));
  };

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value); // New handler
  };

  const handleAddUser = () => {
    if (!userEmail) {
      alert('User email field is empty.');
      return;
    }
    addUserToChat(userEmail) // New handler
    .then(() => { // after successfully adding the user
      alert('Congratulations, user added!'); // show an alert message
      setUserEmail(''); // clear the input field
    })
    .catch(error => console.error('Error adding user to chat:', error));

  };


  const handleCloseChat = () => {
    navigate('/home'); // navigate to home
    setViewState('chatList'); // if you still want to keep the old logic
    
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (!chatId) return;
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/messages`, {
          credentials: 'include', 
        });
        
        if (response.ok) {
          const data = await response.json();
          
          setMessages(data);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
  
    fetchMessages();
  }, [chatId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  
  const addUserToChat = (email) => {
    if (!chatId) return Promise.reject(new Error('No chat ID'));
  
    return fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/users`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        
      },
      credentials: 'include',
      body: JSON.stringify({
        email: email,
      }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      
    })
    .catch((error) => console.error('Error adding user to chat:', error));
  };


  /*Message app info disclaimer */
  const [isSubMessageVisible, setIsSubMessageVisible] = useState(false);

  const toggleSubMessage = () => {
      setIsSubMessageVisible(!isSubMessageVisible);
  };

  const handleDeleteMessage = async (messageId) => {
      // Confirm with the user
    const isConfirmed = window.confirm("Are you sure you want to delete this message?");
    
    if (!isConfirmed) {
      // If the user does not confirm, exit the function early
      return;
    }
    
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/messages/${messageId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          
        },
        credentials: 'include',
      });
  
      if (response.ok) {
        // Remove the message from the local state to update UI
        setMessages(currentMessages => currentMessages.filter(message => message._id !== messageId));
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  

  return (
    <div className="chat-container">
      <button className="close-button" onClick={handleCloseChat}>X</button>
      <button className="portfolio-btn2" onClick={toggleSubMessage}>Portfolio info</button>
      <div className={`chat-list-container ${isChatListModalExpanded ? 'expanded' : ''}`}>
        
        
        <div className="chat-title">
          <h1>Portfolio Message App</h1>
          
        </div>
        <div>

        </div>
        <div className="chat-content">
          
          <button className="chat-list-button" onClick={toggleChatListModal}>
            {isChatListModalExpanded ? 'Hide Chat List (Please select a chat)' : 'Your Chat List (Click To open)'}
          </button>
          
            {isSubMessageVisible && 
            <div className="sub-message2">
                Welcome to my Portfolio Message App! 
                The Portfolio Message App is a full-stack application that utilizes MongoDB as its database. MongoDB stores chat and message data, facilitating interactions such as posting messages, creating chats, adding users to chats, and fetching messages. The backend is structured with Node.js, making the application robust and scalable, and allows seamless data flow from the database to the user interface.
                The frontend is developed using React, providing an interactive user interface. It allows real-time updates, managing chat lists, creating new chats, and adding users to chats. User interactions are authenticated to ensure security and functionality specific to each user, enhancing the application’s reliability and personalization.
                The application ensures a smooth, real-time chatting experience, where every component, from the database to the user interface, works cohesively, providing a reliable and user-friendly messaging platform.
            </div>
            }

          <div className={`chat-list-modal ${isChatListModalExpanded ? 'expanded' : ''}`}>
            <ChatList chats={chats} selectChat={selectChat} setViewState={setViewState} deleteChat={deleteChat} profileImages={profileImages}/>
          </div>
          
          <div className="create-chat-button-container">
            <button className="create-chat-button" onClick={() => setShowNewChatForm(!showNewChatForm)}>Create new chat</button>
            
            {showNewChatForm && (
              <div className="new-chat-form-container">
                <input 
                  type="text" 
                  value={newChatTitle} 
                  onChange={e => setNewChatTitle(e.target.value)} 
                  placeholder="Enter new chat title..." 
                />
                <button className="new-chat-form-button" onClick={handleCreateNewChat}>Submit</button>
              </div>
            )}
          </div>
        </div> 

      <div className={`chat-name-and-messages ${isChatListModalExpanded ? 'expanded' : ''}`}>
        {chatName && 
        <h2 className="chat-name">{chatName} </h2>}
        
        {chatId && (
          <div className="add-user-container">
            <div>
              <input 
                type="text" 
                value={userEmail} 
                onChange={handleUserEmailChange} 
                placeholder="Enter email of user to add..." 
              />
              <button onClick={handleAddUser}>Add User</button>
            </div>
          </div>
        )}
        
        <div className={`message-container ${isChatListModalExpanded ? 'expanded' : ''}`}>
                {messages.map((message) => (
          <div key={message._id} className="message">
            {message.user.profileImage !== undefined &&
              <img 
                src={profileImages[message.user.profileImage]} 
                alt={`${message.user.firstName} ${message.user.lastName}`} 
                className="message-profile-pic" 
              />
            }
            <p>
              <strong>{`${message.user.firstName} ${message.user.lastName}`}</strong>
              {`: ${message.message}`}
            </p>
            {/* Add delete button next to the user's messages */}
            {userId === message.user._id && (
              <button 
              className="delete-message-button" 
              onClick={() => handleDeleteMessage(message._id)}
            >
              Delete
            </button>
            )}
          </div>
        ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
  
      {chatId && (
        <div className="input-container">
          <input 
            type="text" 
            value={newMessage} 
            onChange={handleMessageChange} 
            placeholder="Type your message..." 
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      )}
    </div>
    </div>
  );
  };
  export default Chat;